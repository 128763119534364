<template>
  <footer v-if="!$root.mainMenuHide">
    <div class="main-footer">
      <div class="social-icon"></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped></style>
