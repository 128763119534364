<template>
  <div id="app">
    <div :class="$root.mainWrapper">
      <router-view name="header" :key="$route.fullPath" />
      <router-view :key="$route.fullPath" />
      <router-view name="footer" />
    </div>
    <router-view name="bottomNav" :key="$route.fullPath" />
  </div>
</template>
<script>
//import axios from "axios";
//import * as firebase from "firebase/app";
//import "firebase/messaging";
//import fbConfig from "./firebase";

export default {
  name: "App"
  // async created() {
  //   let config = fbConfig;
  //   if (!firebase.apps.length) {
  //     firebase.initializeApp(config);
  //   }
  //   const messaging = firebase.messaging();
  //   try {
  //     // Let's check if the browser supports notifications
  //     if (!("Notification" in window)) {
  //       console.log("This browser does not support desktop notification");
  //     } else if (Notification.permission === "default") {
  //       const currentToken = await messaging.getToken();
  //       if (currentToken) {
  //         console.log("current FCM token =", currentToken);
  //         let host = this.$root.url();
  //         const path = "api/fcm/webTokenSave";
  //         const url = `${host}/${path}`;
  //         let data = {
  //           fcm_token: currentToken,
  //           app_name: "xoshplay-web"
  //         };
  //         console.log("url =", url);
  //         let response = await axios.post(url, data, {
  //           headers: {
  //             "X-Requested-With": "XMLHttpRequest"
  //           }
  //         });
  //         console.log("response :", response);
  //       }
  //     }
  //     console.log("notification status :", Notification.permission);
  //   } catch (e) {
  //     console.log("An error occurred while retrieving token. ", e);
  //   }
  //
  //   messaging.onMessage(function(payload) {
  //     console.log("Message received. ", payload);
  //     // ...
  //   });
  // }
};
</script>
<style></style>
