<template>
  <div class="bottom-nav" v-if="bottomMenuHide">
    <ul>
      <li v-bind:class="{ 'game-active': isActive == 1 }">
        <router-link to="/"
          ><img src="../assets/games-icons-color.svg" /> Games</router-link
        >
      </li>
      <li v-bind:class="{ 'game-active': isActive == 3 }">
        <router-link to="/video"
          ><img src="../assets/video-icons-color.svg" /> Videos</router-link
        >
      </li>
      <li v-bind:class="{ 'game-active': isActive == 4 }">
        <router-link to="/community"
          ><img
            src="../assets/community-icons-color.svg"
          />Community</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BottomNav",
  data: () => {
    return {
      isActive: 0,
      bottomMenuHide: true
    };
  },
  created() {
    this.isActive =
      this.$route.name === "Home"
        ? 1
        : this.$route.name === "Shop"
        ? 2
        : this.$route.name === "Video"
        ? 3
        : this.$route.name === "Community"
        ? 4
        : 0;
  }
};
</script>

<style scoped></style>
