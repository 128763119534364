import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
//import fbConfig from "./firebase";
import Fragment from "vue-fragment";
import VueAnalytics from "vue-analytics";
//import * as firebase from "firebase/app";
//import "firebase/messaging";

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: "UA-155148996-2",
  router
});
Vue.use(Fragment.Plugin);
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (document.title) {
    next();
  }
});
// let config = fbConfig;
// if (!firebase.apps.length) {
//   firebase.initializeApp(config);
// }

//Vue.prototype.$messaging = firebase.messaging();
// if ("serviceWorker" in navigator) {
//   if (!firebase.apps.length) {
//     navigator.serviceWorker
//       .register("/firebase-messaging-sw.js")
//       .then(registration => {
//         Vue.prototype.$messaging.useServiceWorker(registration);
//       })
//       .catch(err => {
//         console.log("Service Worker registration failed: ", err);
//       });
//   }
// }
new Vue({
  data: {
    bottomMenuHide: true,
    mainWrapper: "wrapper-main",
    mainMenuHide: true,
    fromState: "",
    classObject: {
      active: false
    }
  },
  methods: {
    url() {
      return process.env.VUE_APP_API_URL;
    }
  },
  watch: {
    $route(to, from) {
      this.fromState = from.name;
      console.log("fromState ", this.fromState);
    }
  },
  router,
  store,
  render: h => h(App)
}).$mount("#app");
